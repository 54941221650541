<template>
    <div class="brand">
        <bread-nav></bread-nav>
        <div class="brand-row" v-for="(cat, index) in list" :key="cat.booth_id">
            <h2>
                <span>{{ cat.booth_name }}</span>
            </h2>
            <div class="brand-list">
                <div class="brand-item" @click="$router.push('/total?brand_id=' + brand.brand_id)" v-for="(brand, brandIdx) in cat.brand" :key="brand.brand_id">
                    <el-image :src="$img(brand.image_url)" fit="cover"></el-image>
                    <p>{{ brand.brand_name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadNav from "@/layout/components/bread-nav"
import { brand } from "@/api/goods/goodscategory"

export default {
    name: "brand",
    components: {
        BreadNav
    },
    data: () => {
        return {
            list: [],
            loading: true
        }
    },
    created() {
        this.getBrand()
    },
    methods: {
        getBrand() {
            brand({})
                .then((res) => {
                    if (res.code != 0) {
                        throw new Error(res.message)
                    }
                    this.loading = false
                    this.list = res.data
                })
                .catch((e) => {
                    this.loading = false
                    this.$message(e.message || "加载失败")
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.bread-nav {
    margin: 30px 0;
}

.brand-row {
    margin-bottom: 30px;

    h2 {
        font-size: 20px;
        color: $ns-text-color-black;
        font-weight: bold;
        border-bottom: 1px solid #d9d9d9;
        line-height: 40px;
        margin-bottom: 20px;

        span {
            border-bottom: 1px solid $base-color;
            padding-right: 30px;
            display: inline-block;
            height: 40px;
        }
    }

    .brand-list {
        &:after {
            display: block;
            content: "";
            clear: both;
        }

        .brand-item {
            border: 1px solid #d9d9d9;
            width: 152px;
            margin-right: 16px;
            margin-bottom: 16px;
            font-size: 0;
            float: left;
            box-sizing: border-box;
            cursor: pointer;

            &:nth-child(9n) {
                margin-right: 0;
            }

            p {
                font-size: $ns-font-size-lg;
                color: $ns-text-color-black;
                text-align: center;
                border-top: 1px solid #d9d9d9;
                line-height: 37px;
            }

            .el-image {
                width: 100%;
                height: 75px;
                object-fit: cover;
            }
        }
    }
}
</style>
